
import { defineComponent } from "vue";
import { translate } from "@/hooks/TranslateService";

export default defineComponent({
  name: "item-1",
  components: {},
  setup() {
    return {
      translate
    }
  },
});
